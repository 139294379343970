import CookieConsent from "vanilla-cookieconsent/dist/cookieconsent.umd.js"
import "vanilla-cookieconsent/dist/cookieconsent.css"

document.addEventListener("DOMContentLoaded", function() {
  if (/\/get-matched/.test(window.location.pathname)) { return }

  CookieConsent.run({
    onModalShow: ({modalName}) => {
      let eventName = "cookie_banner_impression_consent_modal"

      if (modalName === "preferencesModal") {
        eventName = "cookie_banner_impression_preferences_modal"
      }
      window.dataLayer.push({event: eventName})
    },

    onFirstConsent: ({cookie}) => {
      const analytics = CookieConsent.acceptedCategory("analytics")
      const marketing = CookieConsent.acceptedCategory("marketing")

      if (analytics && marketing) {
        window.dataLayer.push({
          event: "cookie_banner_consent_accept_all"
        })

      } else if (!analytics && !marketing) {
        window.dataLayer.push({
          event: "cookie_banner_consent_reject_all"
        })

      } else if (analytics && !marketing) {
        window.dataLayer.push({
          event: "cookie_banner_consent_analytics_only"
        })

      } else if (!analytics && marketing) {
        window.dataLayer.push({
          event: "cookie_banner_consent_marketing_only"
        })
      }
    },

    guiOptions: {
      consentModal: {
        layout: "box wide",
        position: "bottom center",
        equalWeightButtons: true,
        flipButtons: false,
      },
      preferencesModal: {
        layout: "box",
        position: "right",
        equalWeightButtons: true,
        flipButtons: false,
      },
    },
    categories: {
      necessary: {
        readOnly: true,
      },
      analytics: {},
      marketing: {},
    },
    language: {
      default: "en",
      autoDetect: "browser",
      translations: {
        en: {
          consentModal: {
            title: "Protect your data",
            description:
              "We use cookies to make our website work better. They improve your experience and help with our advertising and analysis. By agreeing, you accept that your data may be shared. You can reject cookie permission at any time. Please see our <a href='/cookie-policy'>Cookie policy</a> for more detail.",
            acceptAllBtn: "Accept all",
            acceptNecessaryBtn: "Reject all",
            showPreferencesBtn: "Manage preferences",
            footer:
              '<a href="/privacy-policy">Privacy Policy</a>\n<a href="/terms-of-service">Terms and conditions</a>',
          },
          preferencesModal: {
            title: "Protect your data",
            acceptAllBtn: "Accept all",
            acceptNecessaryBtn: "Reject all",
            savePreferencesBtn: "Save preferences",
            closeIconLabel: "Close modal",
            serviceCounterLabel: "Service|Services",
            sections: [
              {
                title: "",
                description:
                  "Course Report and its partners place cookies on this website and use non-sensitive data from your device to improve the products offered and to personalize advertising offers and other web content. Because we respect your right to privacy, you can choose not to allow certain types of cookies. However, blocking certain cookies may limit your use of the website and the services offered.",
              },
              {
                title:
                  'Strictly Necessary Cookies <span class="pm__badge">Always Enabled</span>',
                description:
                  "These cookies are necessary for the website to function and cannot be switched off by our systems. As a rule, these cookies are only set in response to actions you take that correspond to a service request, such as setting your privacy preferences, logging in or filling out forms. You can change your browser settings to disable these cookies, but this may prevent you from accessing and using the website. These cookies do not store any personal data.",
                linkedCategory: "necessary",
              },
              {
                title: "Analytics Cookies",
                description:
                  "These cookies allow us to count visits and traffic sources so that we can measure and improve the performance of our site. They help us understand which pages are the most popular, which are the least visited and how visitors move around our site. The information collected is aggregated and therefore anonymous and if you do not allow these cookies, we will not know when you have visited our website.",
                linkedCategory: "analytics",
              },
              {
                title: "Marketing Cookies",
                description:
                  "These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.",
                linkedCategory: "marketing",
              },
            ],
          },
        },
      },
    },
  });
})
